import Service from './Service';

class AppConstantService extends Service {
    constructor() {
        super();
    }
    async get(pageParam) {
        return this.callApiGet(`/appConstant${pageParam}`);
    }
    async create(postData) {
        return this.callApiPost(`/appConstant`, postData);
    }
    async update(postData) {
        return this.callApiPut(`/appConstant`, postData);
    }
    async delete(id) {
        return this.callApiDelete(`/appConstant?_ids=${id}`);
    }
}
export default AppConstantService
/* route.js
            {
              path: "app-constant",
              name: "app-app-constant",
              component: () => import("@/views/App/pages/app/AppConstant"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  { text: "model.app_constant.app_constant", href: "", disabled: true }
                ],
                pageTitle: { text: "model.app_constant.app_constant", icon: "mdi-api"}
              }
            },
*/

/* UseMenuApi.js
        {
          title: "model.app_constant.app_constant",
          icon: "mdi-file-outline",
          to: "/app/app-constant"
         }
*/
