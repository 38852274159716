<template>
  <v-container
    id="page-appConstant"
    fluid
  >

    <base-wee-sketlon-loader
      :loading="state.loading"
      type="table-heading, table-thead, table-tbody, table-tfoot"
      :no="1"
    />

    <!-- Table  -->
    <wee-simple-table
      v-if="!state.loading"
      :headers="fillableHeaders"
      :title="$t('model.app_constant.app_constant')"
      :tr-list="filteredList"
      :pages="pages"
      :sort="sort"
      @update-search="searchTxt = $event"
      @on-item-click="onItemClick"
      @on-item-delete="onBeforeDeleteItem"
      @on-open-new-form="onOpenNewForm"
      @on-advance-search="advanceSearch"
      @on-reload-page="onReload"
    >
      <!-- <template v-slot:theader></template> 
      <template v-slot:tbody></template> 
      <template v-slot:tpaging></template>  -->
    </wee-simple-table>

    <app-constant-form v-model="entity" :edit-mode="editMode" :open="openNewForm" :processing="isProcessing" @close="openNewForm = false" @save="onSave"/>
    <wee-confirm ref="weeConfirmRef"></wee-confirm>
    <wee-toast ref="weeToastRef"></wee-toast>
  </v-container>
</template>

<script>
import { vLog } from "@/plugins/util";
//service
import AppConstantService from "@/api/AppConstantService";
import useCrudApi from "@/composition/UseCrudApi";
import { toRefs, onBeforeUnmount} from "@vue/composition-api";
export default {
  name: "page-appConstant",
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast"),
    WeeSimpleTable: () => import("@/components/WeeSimpleTable"),
    AppConstantForm: () => import("./AppConstantForm"),
  },
  setup(props, { refs, root }) {
    const appConstantService = new AppConstantService();
//column, label, searchable, sortable, fillable, image, avatar status, date, datetime 
    const tableHeaders = [
      {
        column: "att_name",
        label: "model.app_constant.att_name",
        searchable: true,
        sortable: true,
        fillable: true,
        //linkable: {external: true},
      },
      {
        column: "att_value",
        label: "model.app_constant.att_value",
        searchable: true,
        sortable: true,
        fillable: true,
        //linkable: {external: true},
      },
      {
        column: "description",
        label: "model.app_constant.description",
        searchable: true,
        sortable: true,
        fillable: true,
        //linkable: {external: true},
      },
      {
        column: "status",
        label: "model.app_constant.status",
        searchable: true,
        sortable: true,
        fillable: true,
    status: true,
        //linkable: {external: true},
      },
      {
        label: "base.tool",
        fillable: true,
        baseTool: true
      }
    ];

    //entity
    const initialItem = {
      id: 0,
      att_name: '',
      att_value: '',
      description: '',
      status: false,
    };

    const {
      crud
    } = useCrudApi(refs, root, appConstantService, initialItem, tableHeaders);

    //fell free to change sort colunm and mode
    //sort.column = "id";
    //sort.mode = "ASC";

    onBeforeUnmount(()=>{
      vLog('onBeforeUnmount')
    });

    return {
      ...toRefs(crud),
    };
  }
};
</script>
